import React from 'react';
import PropTypes from 'prop-types';

import './tableRankingTitle.scss';
import { Row, Col } from 'react-flexbox-grid';

import upgreenrow from '../../assets/images/upgreenrow.svg';

const TableRankingTitle = ({ segmentOrder, setSegmentOrder }) => {
  const renderHeader = () => {
    return (
      <Col xs={12} className='table-ranking'>
        <Row className='table-ranking_title-container'>
          <Col xs={3} md={2} className='table-ranking_title'>
            POS
          </Col>
          <Col xs={6} md={8} className='table-ranking_title'>
            NOMBRE
          </Col>
          {/* <Col
            xs={2}
            className='table-ranking_title press'
            onClick={() => {
              setSegmentOrder(true);
            }}
          >
            SEGMENTO
            {segmentOrder && (
              <img src={upgreenrow} alt='user' className='up-icon' />
            )}
          </Col> */}
          <Col
            xs={3}
            md={2}
            className='table-ranking_title press'
            // onClick={() => {
            //   setSegmentOrder(false);
            // }}
          >
            TOTAL
            {/* {!segmentOrder && (
              <img src={upgreenrow} alt='user' className='up-icon' />
            )} */}
          </Col>
        </Row>
      </Col>
    );
  };
  return renderHeader();
};

TableRankingTitle.propTypes = {
  segmentOrder: PropTypes.bool.isRequired,
  setSegmentOrder: PropTypes.func.isRequired,
};

export default TableRankingTitle;
