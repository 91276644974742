import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { TextField, FormControl, List, ListItem } from '@material-ui/core';

import filterImg from '../../assets/images/filtros.svg';
import close from '../../assets/images/close.svg';
import triathlon from '../../assets/images/triatlon.svg';
import bike from '../../assets/images/bici.svg';
import config from '../../config';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';

import './filterModal.scss';

const FilterModal = ({
  setShowLoading,
  isIndividual,
  closeModal,
  selectedCityFilter,
  selectedSegmentFilter,
  selectedGenderFilter,
  selectedGenderCategoryFilter,
  selectedAgeCategoryFilter,
  selectedSportFilter,
  setSelectedCityFilter,
  setSelectedSegmentFilter,
  setSelectedGenderFilter,
  setSelectedGenderCategoryFilter,
  setSelectedSportFilter,
  setChangeIndividual,
  selectedWaveFilter,
  setSelectedWaveFilter,
  selectedCategory,
}) => {
  const [citySearch, setCitySearch] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedGenderCategory, setSelectedGenderCategory] = useState(null);
  const [selectedAgeCategory, setSelectedAgeCategory] = useState(null);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedWave, setSelectedWave] = useState(null);

  useEffect(() => {
    setSelectedCity(selectedCityFilter);
    setSelectedSegment(selectedSegmentFilter);
    setSelectedGender(selectedGenderFilter);
    setSelectedGenderCategory(selectedGenderCategoryFilter);
    setSelectedGenderCategory(selectedGenderCategoryFilter);
    setSelectedAgeCategory(selectedAgeCategoryFilter);
    setSelectedSport(selectedSportFilter);
    setSelectedWave(selectedWaveFilter);
    setCitySearch(
      selectedCityFilter
        ? `${selectedCityFilter.name}, ${selectedCityFilter.state_code}`
        : ''
    );
  }, []);

  const filter = () => {
    setSelectedCityFilter(selectedCity);
    setSelectedSegmentFilter(selectedSegment);
    setSelectedGenderFilter(selectedGender);
    setSelectedGenderCategoryFilter(selectedGenderCategory);
    setSelectedSportFilter(selectedSport);
    setSelectedWaveFilter(selectedWave);
    closeModal();
    setChangeIndividual(new Date().getTime());

    window.scrollTo(0, 0);
  };

  const getCityInfo = async (searchTerm) => {
    const athlete = JSON.parse(localStorage.getItem(config.athleteLogged));

    setShowLoading(true);
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };
    const params = {
      pagination: {
        page: 1,
        limit: 5,
      },
      query: {
        country_code: 'CO',
      },
      search: searchTerm,
    };

    const url = new URL(`${config.apiUrl}/cities`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const citiesDocs = await handleResponse(response);
      setCities(citiesDocs.docs);
    } catch (error) {
      console.log(error);
    }
  };

  const selectCity = (city) => {
    setSelectedCity(city);
    setCitySearch(`${city.name}, ${city.state_code}`);
    setCities([]);
  };

  const debouncedGetCityInfo = useCallback(debounce(getCityInfo, 500), []);

  const changeInput = (inputSet) => (event) => {
    if (event.target.value.length === 0) {
      setSelectedCity(null);
    }
    inputSet(event.target.value);
    if (event.target.value.length > 0) {
      debouncedGetCityInfo(event.target.value);
    }
  };

  // useEffect(() => {
  //   if (citySearch) {
  //     debouncedGetCityInfo(citySearch);
  //   }
  // }, [citySearch]);

  return (
    <div className='filter-modal'>
      <div className='filter-modal_card'>
        <div className='filter-modal_card-header'>
          <div className='filter-modal_card-header-left'>
            <img
              src={filterImg}
              alt='filter'
              className='filter-modal_card-header_filter'
            />
            <div className='filter-modal_card-header_title'>FILTROS</div>
            <div
              className='close-button'
              onClick={() => {
                closeModal();
              }}
            >
              <img src={close} alt='close' className='close-icon' />
            </div>
          </div>
        </div>
        <div className='filter-modal_card-body'>
          {/* {isIndividual && !isIndividualCalendar && (
            <div className='filter-modal_card-section'>
              <div className='filter-modal_card-section-title'>SEGMENTO</div>
              <div className='tags-container'>
                <div
                  onClick={() => {
                    setSelectedSegment(null);
                  }}
                  className={`tag${!selectedSegment ? ' selected' : ''}`}
                >
                  Oficial
                </div>
                <div
                  onClick={() => {
                    setSelectedSegment('elapsedTime1Q');
                  }}
                  className={`tag${
                    selectedSegment === 'elapsedTime1Q' ? ' selected' : ''
                  }`}
                >
                  Split
                </div>
              </div>
            </div>
          )} */}
          {/* {!isIndividual && (
            <div className='filter-modal_card-section'>
              <div className='filter-modal_card-section-title'>GENERO</div>
              <div className='tags-container'>
                <div
                  onClick={() => {
                    setSelectedGenderCategory(null);
                  }}
                  className={`tag${!selectedGenderCategory ? ' selected' : ''}`}
                >
                  Todos
                </div>
                <div
                  onClick={() => {
                    setSelectedGenderCategory('mens');
                  }}
                  className={`tag${
                    selectedGenderCategory === 'mens' ? ' selected' : ''
                  }`}
                >
                  Hombre
                </div>
                <div
                  onClick={() => {
                    setSelectedGenderCategory('women');
                  }}
                  className={`tag${
                    selectedGenderCategory === 'women' ? ' selected' : ''
                  }`}
                >
                  Mujer
                </div>
                <div
                  onClick={() => {
                    setSelectedGenderCategory('mixed');
                  }}
                  className={`tag${
                    selectedGenderCategory === 'mixed' ? ' selected' : ''
                  }`}
                >
                  Mixto
                </div>
              </div>
            </div>
          )} */}
          {isIndividual && selectedCategory === 'amateur' && (
            <div className='filter-modal_card-section'>
              <div className='filter-modal_card-section-title'>OLAS</div>
              <div className='tags-container'>
                <div
                  onClick={() => {
                    setSelectedWave(null);
                  }}
                  className={`tag${!selectedWave ? ' selected' : ''}`}
                >
                  Todos
                </div>
                <div
                  onClick={() => {
                    setSelectedWave('SUB 37');
                  }}
                  className={`tag${
                    selectedWave === 'SUB 37' ? ' selected' : ''
                  }`}
                >
                  {'SUB 37'}
                </div>
                <div
                  onClick={() => {
                    setSelectedWave('SUB 40');
                  }}
                  className={`tag${
                    selectedWave === 'SUB 40' ? ' selected' : ''
                  }`}
                >
                  {'SUB 40'}
                </div>
                <div
                  onClick={() => {
                    setSelectedWave('SUB 45');
                  }}
                  className={`tag${
                    selectedWave === 'SUB 45' ? ' selected' : ''
                  }`}
                >
                  {'SUB 45'}
                </div>
                <div
                  onClick={() => {
                    setSelectedWave('SUB 50');
                  }}
                  className={`tag${
                    selectedWave === 'SUB 50' ? ' selected' : ''
                  }`}
                >
                  {'SUB 50'}
                </div>
              </div>
            </div>
          )}
          {isIndividual && (
            <div className='filter-modal_card-section'>
              <div className='filter-modal_card-section-title'>GENERO</div>
              <div className='tags-container'>
                <div
                  onClick={() => {
                    setSelectedGender(null);
                  }}
                  className={`tag${!selectedGender ? ' selected' : ''}`}
                >
                  Todos
                </div>
                <div
                  onClick={() => {
                    setSelectedGender('male');
                  }}
                  className={`tag${
                    selectedGender === 'male' ? ' selected' : ''
                  }`}
                >
                  Hombre
                </div>
                <div
                  onClick={() => {
                    setSelectedGender('female');
                  }}
                  className={`tag${
                    selectedGender === 'female' ? ' selected' : ''
                  }`}
                >
                  Mujer
                </div>
              </div>
            </div>
          )}
          {/* {!isIndividual && (
            <div className='filter-modal_card-section'>
              <div className='filter-modal_card-section-title'>CATEGORIA</div>
              <div className='tags-container'>
                <div
                  onClick={() => {
                    setSelectedCategory(null);
                  }}
                  className={`tag${!selectedCategory ? ' selected' : ''}`}
                >
                  Todos
                </div>
                <div
                  onClick={() => {
                    setSelectedCategory('<=100');
                  }}
                  className={`tag${
                    selectedCategory === '<=100' ? ' selected' : ''
                  }`}
                >
                  {'<100'}
                </div>
                <div
                  onClick={() => {
                    setSelectedCategory('101-120');
                  }}
                  className={`tag${
                    selectedCategory === '101-120' ? ' selected' : ''
                  }`}
                >
                  {'101-120'}
                </div>
                <div
                  onClick={() => {
                    setSelectedCategory('>=121');
                  }}
                  className={`tag${
                    selectedCategory === '>=121' ? ' selected' : ''
                  }`}
                >
                  {'>121'}
                </div>
              </div>
            </div>
          )} */}
          {/* {isIndividual && (
            <div className='filter-modal_card-section'>
              <div className='filter-modal_card-section-title'>DEPORTE</div>
              <div className='tags-container'>
                <div
                  onClick={() => {
                    setSelectedSport(
                      selectedSport === 'cyclist' ? null : 'cyclist'
                    );
                  }}
                  className={`sport-container${
                    selectedSport === 'cyclist' ? ' selected' : ''
                  }`}
                >
                  <img src={bike} className='bike-img' alt='bike' />
                </div>
                <div
                  onClick={() => {
                    setSelectedSport(
                      selectedSport === 'triathlete' ? null : 'triathlete'
                    );
                  }}
                  className={`sport-container${
                    selectedSport === 'triathlete' ? ' selected' : ''
                  }`}
                >
                  <img
                    src={triathlon}
                    className='triathlon-img'
                    alt='triathlon'
                  />
                </div>
              </div>
            </div>
          )} */}
          {/* <FormControl fullWidth>
            <TextField
              onChange={changeInput(setCitySearch)}
              value={citySearch}
              id='citySearch'
              label='BUSCAR CIUDAD'
            />
          </FormControl>
          {cities && citySearch && (
            <List>
              {cities.map((city) => {
                return (
                  <ListItem
                    className='city-item'
                    key={city._id}
                    onClick={() => {
                      selectCity(city);
                    }}
                  >{`${city.name}, ${city.state_code}`}</ListItem>
                );
              })}
            </List>
          )} */}

          <button
            onClick={filter}
            className='filter-button'
            disabled={
              !(
                !!selectedCity ||
                !!selectedGender ||
                !!selectedSegment ||
                !!selectedGenderCategory ||
                !!selectedAgeCategory ||
                !!selectedCategory ||
                !!selectedWave ||
                !!selectedSport
              )
            }
          >
            Filtrar
          </button>
        </div>
      </div>
    </div>
  );
};

FilterModal.propTypes = {
  setShowLoading: PropTypes.func.isRequired,
  setSelectedCityFilter: PropTypes.func.isRequired,
  setChangeIndividual: PropTypes.func.isRequired,
  setSelectedSegmentFilter: PropTypes.func.isRequired,
  setSelectedGenderFilter: PropTypes.func.isRequired,
  setSelectedGenderCategoryFilter: PropTypes.func.isRequired,
  setSelectedSportFilter: PropTypes.func.isRequired,
  setSelectedWaveFilter: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isIndividual: PropTypes.bool.isRequired,
  selectedCityFilter: PropTypes.object,
  selectedSegmentFilter: PropTypes.string,
  selectedWaveFilter: PropTypes.string,
  selectedGenderFilter: PropTypes.string,
  selectedGenderCategoryFilter: PropTypes.string,
  selectedSportFilter: PropTypes.string,
  selectedCategory: PropTypes.string,
};

FilterModal.defaultProps = {
  selectedCityFilter: null,
  selectedSegmentFilter: null,
  selectedGenderFilter: null,
  selectedGenderCategoryFilter: null,
  selectedSportFilter: null,
};

export default FilterModal;
