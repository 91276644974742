import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'react-flexbox-grid';
import config from '../../config';
import TableRankingIndividualItem from '../TableRankingIndividualItem';
import EmptyState from '../EmptyState';
import { handleResponse, serializeQuery } from '../../utils/handleResponse';

const TableRankingIndividual = ({
  setShowLoading,
  individualFavorites,
  saveFavorite,
  setSelectedUser,
  deleteFavorite,
  selectedCityFilter,
  selectedSegmentFilter,
  selectedGenderFilter,
  selectedSportFilter,
  selectedWaveFilter,
  selectedCategory,
  changeIndividual,
  selectedTab,
  segmentOrder,
}) => {
  const [rakings, setRakings] = useState([]);
  const [rakingsFavorite, setRakingsFavorite] = useState([]);

  const getTabTimeName = () => {
    if (segmentOrder) {
      switch (selectedTab) {
        case 0:
          return 'swim';
        case 1:
          return 't1';
        case 2:
          return 'km2';
        case 3:
          return 'km88';
        case 4:
          return 'bike';
        case 5:
          return 't2';
        case 6:
          return 'km22';
        case 7:
          return 'km11';
        case 8:
          return 'km19';
        case 9:
          return 'run';
        default:
          return 'elapsedTime';
      }
    } else {
      switch (selectedTab) {
        case 0:
          return 'swim';
        case 1:
          return 't1';
        case 2:
          return 'km2';
        case 3:
          return 'km88';
        case 4:
          return 'bike';
        case 5:
          return 't2';
        case 6:
          return 'km22';
        case 7:
          return 'km11';
        case 8:
          return 'km19';
        case 9:
          return 'run';
        default:
          return 'elapsedTime';
      }
    }
  };

  const getCalendar = async (user) => {
    setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem(config.athleteLogged));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
      query: {
        elapsedTimeField: getTabTimeName(),
      },
    };
    if (selectedCityFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cityId'] = selectedCityFilter.id;
    }
    if (selectedSegmentFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['elapsedTimeField'] = selectedSegmentFilter;
    }
    if (selectedGenderFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['gender'] = selectedGenderFilter;
    }
    if (selectedSportFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['sportType'] = selectedSportFilter;
    }
    if (selectedWaveFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['wave'] = selectedWaveFilter;
    }
    if (selectedCategory) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['category'] = selectedCategory;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(`${config.apiUrl}/api/race-events/individual-ranking`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    setShowLoading(false);
    try {
      const newRankings = await handleResponse(response);
      setRakings(newRankings.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getCalendarFavorite = async () => {
    // setShowSecondLoading(true);
    const athlete = JSON.parse(localStorage.getItem(config.athleteLogged));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };

    const requestOptions = {
      method: 'GET',
      headers,
    };

    const params = {
      pagination: {
        page: 1,
        limit: 1000,
      },
      query: {
        elapsedTimeField: getTabTimeName(),
        individualRankingFavorite: true,
      },
    };
    if (selectedCityFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['cityId'] = selectedCityFilter.id;
    }
    if (selectedSegmentFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['elapsedTimeField'] = selectedSegmentFilter;
    }
    if (selectedGenderFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['gender'] = selectedGenderFilter;
    }
    if (selectedSportFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['sportType'] = selectedSportFilter;
    }
    if (selectedWaveFilter) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['wave'] = selectedWaveFilter;
    }
    if (selectedCategory) {
      if (!params['query']) {
        params['query'] = {};
      }
      params['query']['category'] = selectedCategory;
    }
    if (!params['query']) {
      params['query'] = {};
    }
    params['query']['guest'] = athlete.athlete.isGuest;

    const url = new URL(`${config.apiUrl}/api/race-events/individual-ranking`);
    url.search = serializeQuery(params);

    const response = await fetch(url, requestOptions);
    // setShowSecondLoading(false);
    try {
      const newRakingsFavorite = await handleResponse(response);
      setRakingsFavorite(newRakingsFavorite.docs);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (individualFavorites) {
      getCalendarFavorite();
    }
  }, [individualFavorites]);

  useEffect(() => {
    if (selectedTab) {
      getCalendar();
      getCalendarFavorite();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (changeIndividual) {
      getCalendar();
      getCalendarFavorite();
    }
  }, [changeIndividual]);

  return (
    <Col xs={12}>
      <Row>
        {rakings.length > 0 &&
          rakingsFavorite.map((raking) => {
            return (
              <TableRankingIndividualItem
                userInfo={raking}
                key={raking._id}
                darkMode
                firstRaking={rakings[0]}
                individualFavorites={individualFavorites}
                saveFavorite={saveFavorite}
                deleteFavorite={deleteFavorite}
                selectedSegmentFilter={selectedSegmentFilter}
                selectedTab={selectedTab}
                selectedCategory={selectedCategory}
                segmentOrder={segmentOrder}
                setSelectedUser={setSelectedUser}
              />
            );
          })}
        {rakings.length === 0 && <EmptyState />}
        {rakings.map((raking) => {
          return (
            <TableRankingIndividualItem
              selectedTab={selectedTab}
              userInfo={raking}
              key={raking._id}
              individualFavorites={individualFavorites}
              saveFavorite={saveFavorite}
              deleteFavorite={deleteFavorite}
              selectedSegmentFilter={selectedSegmentFilter}
              firstRaking={rakings[0]}
              selectedCategory={selectedCategory}
              segmentOrder={segmentOrder}
              setSelectedUser={setSelectedUser}
            />
          );
        })}
      </Row>
    </Col>
  );
};

TableRankingIndividual.propTypes = {
  setShowLoading: PropTypes.func.isRequired,
  setShowSecondLoading: PropTypes.func.isRequired,
  raceDay: PropTypes.object.isRequired,
  raceEvent: PropTypes.object.isRequired,
  individualFavorites: PropTypes.array.isRequired,
  saveFavorite: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  selectedCityFilter: PropTypes.object,
  selectedSegmentFilter: PropTypes.string,
  selectedGenderFilter: PropTypes.string,
  selectedSportFilter: PropTypes.string,
  selectedWaveFilter: PropTypes.string,
  selectedCategory: PropTypes.string,
  changeIndividual: PropTypes.number,
  selectedTab: PropTypes.number,
  segmentOrder: PropTypes.bool,
};

TableRankingIndividual.defaultProps = {
  selectedCityFilter: null,
  selectedSegmentFilter: null,
  selectedAgeCategoryFilter: null,
  selectedGenderFilter: null,
  selectedSportFilter: null,
  selectedWaveFilter: null,
  selectedCategory: null,
  changeIndividual: null,
};

export default TableRankingIndividual;
