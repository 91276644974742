import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import stravaLogo from '../../assets/images/strava.svg';
import femaleDefaultFoto from '../../assets/images/female_default.png';
import maleDefaultFoto from '../../assets/images/male_default.png';
import downIcon from '../../assets/images/down-chevron.svg';
import fireicon from '../../assets/images/fireicon.svg';
import upgreenrow from '../../assets/images/upgreenrow.svg';
import downredrow from '../../assets/images/downredrow.svg';

import { Row, Col } from 'react-flexbox-grid';

const TableRankingIndividualItem = ({
  userInfo,
  calendar,
  firstRaking,
  darkMode,
  individualFavorites,
  saveFavorite,
  deleteFavorite,
  selectedSegmentFilter,
  selectedTab,
  selectedCategory,
  segmentOrder,
  setSelectedUser,
}) => {
  const openStravaProfile = (stravaId) => {
    window.open(`https://www.strava.com/athletes/${stravaId}`, '_newtab');
  };

  const openAcordion = () => {
    setSelectedUser(userInfo);
  };

  const getCategory = () => {
    return userInfo.category === 'professional' ? 'Narinian' : userInfo.wave;
  };

  const getTabTimeName = () => {
    switch (selectedTab) {
      case 0:
        return 'swim';
      case 1:
        return 't1';
      case 2:
        return 'km2';
      case 3:
        return 'km88';
      case 4:
        return 'bike';
      case 5:
        return 't2';
      case 6:
        return 'km22';
      case 7:
        return 'km11';
      case 8:
        return 'km19';
      case 9:
        return 'run';
      default:
        return 'elapsedTime';
    }
  };

  const getTabTimeNamePartial = () => {
    switch (selectedTab) {
      case 0:
        return 'swim';
      case 1:
        return 't1';
      case 2:
        return 'km2';
      case 3:
        return 'km88';
      case 4:
        return 'bike';
      case 5:
        return 't2';
      case 6:
        return 'km22';
      case 7:
        return 'km11';
      case 8:
        return 'km19';
      case 9:
        return 'run';
      default:
        return 'elapsedTime';
    }
  };

  const fixNumber = (number) => {
    return Math.abs(number);
  };

  const addButtonText = () => {
    let buttonText = '+';
    let saveFunction = saveFavorite;
    let favoriteId = userInfo._id;
    if (individualFavorites) {
      individualFavorites.forEach((individualFavorite) => {
        if (individualFavorite.favorite === userInfo._id) {
          buttonText = '-';
          favoriteId = individualFavorite._id;
          saveFunction = deleteFavorite;
        }
      });
    }
    return (
      <div
        onClick={() => {
          saveFunction(favoriteId);
        }}
        className='add-button'
      >
        {buttonText}
      </div>
    );
  };

  const showTime = (timeDate, status, note) => {
    const dnf = status === 2;
    const dq = status === 3;

    const validTime =
      !dnf && !dq && ((status === 0 && timeDate < 28800) || timeDate < 28800);

    const waiting =
      !dnf && !dq && (timeDate >= 28800 || (status === 1 && timeDate >= 28800));

    return (
      <div className={`${waiting ? 'waiting' : ''}`}>
        {dnf && 'DNF'}
        {dq && 'DQ'}
        {waiting && 'Esperando tiempo'}
        {validTime && new Date(timeDate * 1000).toISOString().substr(11, 8)}
        {/* {validTime && firstRaking._id !== userInfo._id && (
            <div className='table-ranking-item_user-info-team'>
              {firstRaking._id !== userInfo._id && <span>{'+ '}</span>}
              {firstRaking._id === userInfo._id
                ? `${new Date(timeDate * 1000).toISOString().substr(11, 8)}`
                : `${new Date(
                    Math.abs(
                      timeDate -
                        firstRaking[
                          selectedSegmentFilter
                            ? selectedSegmentFilter
                            : 'elapsedTime'
                        ]
                    ) * 1000
                  )
                    .toISOString()
                    .substr(11, 8)}`}
            </div>
          )} */}
        {note !== '' && <div className='note-text'>{note}</div>}
      </div>
    );
  };
  return (
    <Col xs={12} className={`table-ranking-item${darkMode ? ' darkMode' : ''}`}>
      <Row className='table-ranking-item_column-container'>
        <Col xs={12}>
          <Row className='table-ranking-item_column-container_main individual'>
            <Col
              xs={3}
              md={2}
              className='table-ranking-item_column position-ranking-column'
            >
              {addButtonText()}
              <div className='raking-buton'>
                <div className='position-text'>{userInfo.rank + 1}</div>
                {selectedCategory && (
                  <div className='ranking-difference'>
                    {segmentOrder && (
                      <>
                        {userInfo[`${getTabTimeName()}RankingDifference`] !==
                          0 &&
                          fixNumber(
                            userInfo[`${getTabTimeName()}RankingDifference`]
                          )}
                        {userInfo[`${getTabTimeName()}RankingDifference`] >
                          0 && (
                          <img
                            src={upgreenrow}
                            alt='user'
                            className='up-icon'
                          />
                        )}
                        {userInfo[`${getTabTimeName()}RankingDifference`] <
                          0 && (
                          <img
                            src={downredrow}
                            alt='user'
                            className='up-icon'
                          />
                        )}
                      </>
                    )}
                    {!segmentOrder && (
                      <>
                        {userInfo[
                          `${getTabTimeNamePartial()}RankingDifference`
                        ] !== 0 &&
                          fixNumber(
                            userInfo[
                              `${getTabTimeNamePartial()}RankingDifference`
                            ]
                          )}
                        {userInfo[
                          `${getTabTimeNamePartial()}RankingDifference`
                        ] > 0 && (
                          <img
                            src={upgreenrow}
                            alt='user'
                            className='up-icon'
                          />
                        )}
                        {userInfo[
                          `${getTabTimeNamePartial()}RankingDifference`
                        ] < 0 && (
                          <img
                            src={downredrow}
                            alt='user'
                            className='up-icon'
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </Col>
            <Col
              xs={6}
              md={8}
              className='table-ranking-item_column'
              onClick={() => {
                if (userInfo.stravaId) {
                  openStravaProfile(userInfo.stravaId);
                }
              }}
            >
              <div className='table-ranking-item_user-img-container'>
                <img
                  src={
                    userInfo.photoUrl ||
                    (userInfo.gender === 'female'
                      ? femaleDefaultFoto
                      : maleDefaultFoto)
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      userInfo.gender === 'female'
                        ? femaleDefaultFoto
                        : maleDefaultFoto;
                  }}
                  alt='user'
                  className={`table-ranking-item_user-img ${userInfo.gender}`}
                />
                {userInfo.stravaId && (
                  <img
                    src={stravaLogo}
                    alt='strava logo'
                    className='strava-logo individual'
                  />
                )}
              </div>
              <div className='table-ranking-item_user-info-container'>
                <div className='table-ranking-item_user-info-name'>
                  {`${userInfo.name} ${userInfo.lastName}`}
                </div>
                <div className='table-ranking-item_user-info-team'>
                  <span className='wave-container'>{getCategory()}</span>
                </div>
              </div>
              {userInfo.category === 'professional' && (
                <div className='table-ranking-item_user-img-container specialized'>
                  <img src={fireicon} alt='user' className='specialized-icon' />
                </div>
              )}
            </Col>
            <Col
              xs={3}
              md={2}
              className='table-ranking-item_column cursor'
              onClick={openAcordion}
            >
              <div className='table-ranking-item_category'>
                {showTime(
                  userInfo[
                    selectedSegmentFilter
                      ? selectedSegmentFilter
                      : getTabTimeName()
                  ],
                  userInfo.status,
                  userInfo.note
                )}
                <img src={downIcon} alt='down' className='down-icon' />
              </div>
            </Col>
            {/* <Col
              xs={3}
              className='table-ranking-item_column cursor'
              onClick={openAcordion}
            >
              <div className='table-ranking-item_category'>
                {showTime(
                  userInfo[
                    selectedSegmentFilter
                      ? selectedSegmentFilter
                      : getTabTimeNamePartial()
                  ],
                  userInfo.status,
                  userInfo.note
                )}
                <img src={downIcon} alt='down' className='down-icon' />
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

TableRankingIndividualItem.propTypes = {
  saveFavorite: PropTypes.func.isRequired,
  setSelectedUser: PropTypes.func.isRequired,
  deleteFavorite: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  firstRaking: PropTypes.object.isRequired,
  calendar: PropTypes.bool,
  darkMode: PropTypes.bool,
  segmentOrder: PropTypes.bool,
  individualFavorites: PropTypes.array.isRequired,
  selectedSegmentFilter: PropTypes.string,
  selectedTab: PropTypes.number,
  selectedCategory: PropTypes.string,
};

TableRankingIndividualItem.defaultProps = {
  calendar: false,
  darkMode: false,
  segmentOrder: false,
  selectedSegmentFilter: null,
  selectedCategory: '',
};

export default TableRankingIndividualItem;
