import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

import stravaLogo from '../../assets/images/strava.svg';
import mortaleslogo from '../../assets/images/mortaleslogo.svg';
import narinologo from '../../assets/images/narinologo.svg';
import femaleDefaultFoto from '../../assets/images/female_default.png';
import maleDefaultFoto from '../../assets/images/male_default.png';
import upgreenrow from '../../assets/images/upgreenrow.svg';
import downredrow from '../../assets/images/downredrow.svg';
import close from '../../assets/images/close.svg';

import swimicon from '../../assets/images/swimicon.svg';
import bikeicon from '../../assets/images/bikeicon.svg';
import runicon from '../../assets/images/runicon.svg';
import finishicon from '../../assets/images/finishicon.svg';

import './userInfoModal.scss';

const TABS = [
  'SWIM',
  'T1',
  'KM 2',
  'KM 88',
  'BIKE',
  'T2',
  'KM 2.2',
  'KM 11',
  'KM 19',
  'RUN',
  'SURVIVOR',
];
const TABS_NUMBER = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const UserInfoModal = ({ closeModal, selectedUser: userInfo }) => {
  useEffect(() => {}, []);

  const getCategory = () => {
    return userInfo.category === 'professional' ? 'Narinian' : userInfo.wave;
  };

  const fixNumber = (number) => {
    return Math.abs(number);
  };

  const getTabTimeName = (selectedTab) => {
    switch (selectedTab) {
      case 0:
        return 'swim';
      case 1:
        return 't1';
      case 2:
        return 'km2';
      case 3:
        return 'km88';
      case 4:
        return 'bike';
      case 5:
        return 't2';
      case 6:
        return 'km22';
      case 7:
        return 'km11';
      case 8:
        return 'km19';
      case 9:
        return 'run';
      default:
        return 'elapsedTime';
    }
  };

  const getTabTimeNamePartial = (selectedTab) => {
    switch (selectedTab) {
      case 0:
        return 'swim';
      case 1:
        return 'km12Partial';
      case 2:
        return 'km486Partial';

      case 3:
        return 'km532Partial';

      case 4:
        return 'km90Partial';

      case 5:
        return 'km90Partial';

      case 6:
        return 't2Km63Partial';

      case 7:
        return 'km98Partial';

      case 8:
        return 'km175Partial';

      case 9:
        return 'adidasKm22Partial';

      case 10:
        return 'adidasKm22Partial';

      default:
        return 'elapsedTime';
    }
  };

  const showTime = (timeDate, status, note) => {
    const dnf = status === 2;
    const dq = status === 3;

    const validTime =
      !dnf && !dq && ((status === 0 && timeDate < 28800) || timeDate < 28800);

    const waiting =
      !dnf && !dq && (timeDate >= 28800 || (status === 1 && timeDate >= 28800));

    return (
      <div className={`${waiting ? 'waiting' : ''}`}>
        {dnf && 'DNF'}
        {dq && 'DQ'}
        {waiting && 'Esperando tiempo'}
        {validTime && new Date(timeDate * 1000).toISOString().substr(11, 8)}
      </div>
    );
  };

  const tabIcon = (selectedTab) => {
    let selectedIcon = '';
    switch (selectedTab) {
      case 0:
      case 1: {
        selectedIcon = swimicon;
        break;
      }
      case 2:
      case 3:
      case 4:
      case 5: {
        selectedIcon = bikeicon;
        break;
      }
      case 6:
      case 7:
      case 8:
      case 9: {
        selectedIcon = runicon;
        break;
      }
      default: {
        selectedIcon = finishicon;
        break;
      }
    }
    return selectedIcon;
  };

  const tabIconName = (selectedTab) => {
    let iconName = '';
    switch (selectedTab) {
      case 0: {
        iconName = 'SWIM';
        break;
      }
      case 1:
      case 2:
      case 3:
      case 4:
      case 5: {
        iconName = 'BIKE';
        break;
      }
      case 11: {
        iconName = 'FINISH';
        break;
      }
      default: {
        iconName = 'RUN';
        break;
      }
    }
    return iconName;
  };
  return (
    <div className='user-info-modal'>
      <div className='user-info-modal_card'>
        <div className='user-info-modal-header'>
          <div className='header-container'>
            <div className='profile-img-container'>
              <img
                src={
                  userInfo.photoUrl ||
                  (userInfo.gender === 'female'
                    ? femaleDefaultFoto
                    : maleDefaultFoto)
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    userInfo.gender === 'female'
                      ? femaleDefaultFoto
                      : maleDefaultFoto;
                }}
                alt='user'
                className={`profile-img ${userInfo.gender}`}
              />
              {userInfo.stravaId && (
                <img
                  src={stravaLogo}
                  alt='strava logo'
                  className='strava-logo individual'
                />
              )}
            </div>
            <div className='profile-name-container'>
              <div className='profile-name'>
                {`${userInfo.name} ${userInfo.lastName}`}
              </div>
              <div className='profile-category'>
                <span className='wave-container'>{getCategory()}</span>
              </div>
            </div>
            <div className='position-container'>
              <div className='position-box'>
                <div className='position-text'>
                  {userInfo[`elapsedTimeRanking`]}
                </div>
                <div className='ranking-difference'>
                  {userInfo[`elapsedTimeRankingDifference`] !== 0 &&
                    fixNumber(userInfo[`elapsedTimeRankingDifference`])}
                  {userInfo[`elapsedTimeRankingDifference`] > 0 && (
                    <img src={upgreenrow} alt='user' className='up-icon' />
                  )}
                  {userInfo[`elapsedTimeRankingDifference`] < 0 && (
                    <img src={downredrow} alt='user' className='up-icon' />
                  )}
                </div>
              </div>
              <div>
                <img
                  src={
                    userInfo.category === 'professional'
                      ? narinologo
                      : mortaleslogo
                  }
                  alt='narino logo'
                  className='narino-logo'
                />
              </div>
            </div>
          </div>
          <div
            className='close-button'
            onClick={() => {
              closeModal();
            }}
          >
            <img src={close} alt='close' className='close-icon' />
          </div>
        </div>
        <div className='user-info-modal_card-body'>
          <div className='segments-table'>
            <div className='position-column'>
              <div className='table-title'>POS</div>
              {TABS_NUMBER.map((item) => {
                return (
                  <div className='position-box' key={`${item}-key`}>
                    <div className='position-text'>
                      {userInfo[`${getTabTimeName(item)}Ranking`]}
                    </div>
                    <div className='ranking-difference'>
                      {userInfo[`${getTabTimeName(item)}RankingDifference`] !==
                        0 &&
                        fixNumber(
                          userInfo[`${getTabTimeName(item)}RankingDifference`]
                        )}
                      {userInfo[`${getTabTimeName(item)}RankingDifference`] >
                        0 && (
                        <img src={upgreenrow} alt='user' className='up-icon' />
                      )}
                      {userInfo[`${getTabTimeName(item)}RankingDifference`] <
                        0 && (
                        <img src={downredrow} alt='user' className='up-icon' />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='segment-column'>
              <div className='table-title'>TIEMPO</div>
              {TABS_NUMBER.map((item) => {
                return (
                  <div className='time-box' key={`${item}-key-segment`}>
                    <div className='main-time'>
                      {showTime(
                        userInfo[getTabTimeName(item)],
                        userInfo.status,
                        userInfo.note
                      )}
                    </div>
                    {/* <div className='partial-time'>
                      {showTime(
                        userInfo[getTabTimeNamePartial(item)],
                        userInfo.status,
                        userInfo.note
                      )}
                    </div> */}
                  </div>
                );
              })}
            </div>
            <div className='type-column'>
              <div className='table-title'>SEGMENTO</div>
              {TABS_NUMBER.map((item) => {
                return (
                  <div className='type-box' key={`${item}-key-type`}>
                    <div className='icon-container'>
                      <img
                        src={tabIcon(item)}
                        alt='type icon'
                        className='type-icon'
                      />
                    </div>
                    <div>{TABS[item]}</div>
                  </div>
                );
              })}
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserInfoModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

UserInfoModal.defaultProps = {};

export default UserInfoModal;
