import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import logo from '../../assets/images/newlogo2.png';
import defaultFoto from '../../assets/images/foto.jpeg';
// import stravaLogo from '../../assets/images/strava.svg';
import './header.scss';

import config from '../../config';

const Header = ({ resetFilters, showReset, signUp }) => {
  const [currentAthlete, setCurrentAthlete] = useState({});

  useEffect(() => {
    const athlete = JSON.parse(localStorage.getItem(config.athleteLogged));
    if (athlete && athlete.athlete) {
      setCurrentAthlete(athlete.athlete);
    }
  }, []);

  return (
    <header className='header'>
      {!signUp && (
        <div className='header_profile-photo-container'>
          <img
            src={currentAthlete.photoUrl || defaultFoto}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultFoto;
            }}
            alt='profile'
            className='header_profile-photo'
          />
        </div>
      )}
      <div className='header_container_logo'>
        <img src={logo} alt='logo' className='header_logo' />
      </div>
      {showReset && !signUp && (
        <div className='reset-filters' onClick={resetFilters}>
          Limpiar Filtros
        </div>
      )}
      {/* <div className='add-button-container'>
        <img
          src={stravaLogo}
          alt='strava logo'
          className='header_strava_logo'
        />
        <div className='add-button'>+</div>
      </div> */}
    </header>
  );
};

Header.propTypes = {
  resetFilters: PropTypes.func.isRequired,
  showReset: PropTypes.bool.isRequired,
  signUp: PropTypes.bool,
};

Header.defaultProps = {
  signUp: false,
};

export default Header;
