import React, { useState } from 'react';
import './tabs.scss';
import PropTypes from 'prop-types';
import swimicon from '../../assets/images/swimicon.svg';
import swimiconselected from '../../assets/images/swimicon-selected.svg';
import bikeicon from '../../assets/images/bikeicon.svg';
import bikeiconselected from '../../assets/images/bikeicon-selected.svg';
import spint from '../../assets/images/icons/spint.svg';
import spintselected from '../../assets/images/icons/spint-selected.svg';
import finishicon from '../../assets/images/finishicon.svg';
import finishiconselected from '../../assets/images/finishicon-selected.svg';
import runicon from '../../assets/images/runicon.svg';
import runiconselected from '../../assets/images/runicon-selected.svg';

const Tabs = ({ tabs, onChange }) => {
  const [selected, setSelected] = useState(0);

  const tabIcon = (tab, selected) => {
    let selectedIcon = '';
    switch (tab) {
      case 'SWIM':
      case 'T1': {
        selectedIcon = selected ? swimiconselected : swimicon;
        break;
      }
      case 'KM 2':
      case 'KM 88':
      case 'T2':
      case 'BIKE': {
        selectedIcon = selected ? bikeiconselected : bikeicon;
        break;
      }
      case 'SURVIVOR': {
        selectedIcon = selected ? finishiconselected : finishicon;
        break;
      }
      default: {
        selectedIcon = selected ? runiconselected : runicon;
        break;
      }
    }
    return selectedIcon;
  };

  return (
    <div className='tabs'>
      {tabs.map((tab, index) => {
        return (
          <div
            key={tab}
            className={`tab${index === selected ? ' selected' : ''}`}
            onClick={() => {
              setSelected(index);
              onChange(index);
            }}
          >
            <img src={tabIcon(tab, index === selected)} alt={tab}></img>{' '}
            <div>{tab}</div>
          </div>
        );
      })}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Tabs;
