import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Loading from '../../components/loading';
import './home.scss';
import Header from '../Header';
import Nav from '../Nav';
import Tabs from '../Tabs';
import TableRankingTitle from '../TableRankingTitle';
import TableRankingIndividual from '../TableRankingIndividual';
import FilterModal from '../FilterModal';
import UserInfoModal from '../UserInfoModal';
import config from '../../config';
import handleResponse from '../../utils/handleResponse';
import banner from '../../assets/images/bannerdek.png';
import bannerMobile from '../../assets/images/bannermobile.png';

// const TABS = [
//   'SWIM + T1',
//   'KM 12',
//   'KM 49',
//   'SONORA',
//   'KM 90',
//   'BIKE',
//   'T2 + KM 6',
//   'KM 10',
//   'KM 18',
//   'ADIDAS',
//   'RUN',
//   'SURVIVOR',
// ];
const TABS = [
  'SWIM',
  'T1',
  'KM 2',
  'KM 88',
  'BIKE',
  'T2',
  'KM 2.2',
  'KM 11',
  'KM 19',
  'RUN',
  'SURVIVOR',
];

const Home = ({ history }) => {
  const [segmentOrder, setSegmentOrder] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSecondLoading, setShowSecondLoading] = useState(false);
  const [showIndividualFavoriteLoading, setShowIndividualFavoriteLoading] =
    useState(false);
  const [individualFavorites, setIndividualFavorites] = useState([]);
  const [selectedCityFilter, setSelectedCityFilter] = useState(null);
  const [selectedSegmentFilter, setSelectedSegmentFilter] = useState(null);
  const [selectedGenderFilter, setSelectedGenderFilter] = useState(null);
  const [selectedWaveFilter, setSelectedWaveFilter] = useState(null);
  const [selectedGenderCategoryFilter, setSelectedGenderCategoryFilter] =
    useState(null);
  const [selectedCategory, setSelectedCategory] = useState('amateur');
  const [selectedAgeCategoryFilter, setSelectedAgeCategoryFilter] =
    useState(null);
  const [selectedSportFilter, setSelectedSportFilter] = useState(null);
  const [changeIndividual, setChangeIndividual] = useState(null);
  const resetFilters = () => {
    setSelectedCityFilter(null);
    setSelectedSegmentFilter(null);
    setSelectedGenderFilter(null);
    setSelectedGenderCategoryFilter(null);
    setSelectedWaveFilter(null);
    setSelectedAgeCategoryFilter(null);
    setSelectedSportFilter(null);
    setChangeIndividual(new Date().getTime());
  };

  useEffect(() => {
    getIndividualFavorites();
    onTabChange(0);
  }, []);

  useEffect(() => {
    resetFilters();
    getIndividualFavorites();
    setChangeIndividual(new Date().getTime());
  }, [selectedCategory]);

  useEffect(() => {
    setChangeIndividual(new Date().getTime());
  }, [segmentOrder]);

  useEffect(() => {
    if (selectedUser) {
      setShowUserInfoModal(true);
    }
  }, [selectedUser]);

  const getIndividualFavorites = async () => {
    // setShowIndividualFavoriteLoading(true);
    const athlete = JSON.parse(localStorage.getItem(config.athleteLogged));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'GET',
      headers,
    };

    let url;
    if (athlete.athlete.isGuest) {
      url = new URL(
        `${config.apiUrl}/api/race-events/individual-guests-ranking-favorites`
      );
    } else {
      url = new URL(
        `${config.apiUrl}/api/race-events/individual-ranking-favorites`
      );
    }

    const response = await fetch(url, requestOptions);
    // setShowIndividualFavoriteLoading(false);
    try {
      const newIndividualFavorites = await handleResponse(response);
      setIndividualFavorites(newIndividualFavorites);
    } catch (error) {
      console.log('error', error);
    }
  };

  const saveFavorite = async (favorite) => {
    // setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem(config.athleteLogged));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        favorite,
      }),
    };

    let url;
    if (athlete.athlete.isGuest) {
      url = new URL(
        `${config.apiUrl}/api/race-events/individual-guests-ranking-favorites`
      );
    } else {
      url = new URL(
        `${config.apiUrl}/api/race-events/individual-ranking-favorites`
      );
    }

    await fetch(url, requestOptions);
    // setShowLoading(false);
    try {
      getIndividualFavorites();
    } catch (error) {
      console.log('error', error);
    }
  };

  const deleteFavorite = async (favorite) => {
    // setShowLoading(true);
    const athlete = JSON.parse(localStorage.getItem(config.athleteLogged));
    const headers = {
      Authorization: `Bearer ${athlete.token}`,
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'DELETE',
      headers,
    };
    const url = new URL(
      `${config.apiUrl}/api/race-events/individual-ranking-favorites/${favorite}`
    );
    await fetch(url, requestOptions);
    // setShowLoading(false);
    try {
      getIndividualFavorites();
    } catch (error) {
      console.log('error', error);
    }
  };

  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = (tab) => {
    setSelectedTab(tab);
    setChangeIndividual(new Date().getTime());
  };

  const renderTable = () => {
    return (
      <TableRankingIndividual
        setShowLoading={setShowLoading}
        setShowSecondLoading={setShowSecondLoading}
        individualFavorites={individualFavorites}
        saveFavorite={saveFavorite}
        deleteFavorite={deleteFavorite}
        selectedCityFilter={selectedCityFilter}
        selectedSegmentFilter={selectedSegmentFilter}
        selectedGenderFilter={selectedGenderFilter}
        selectedSportFilter={selectedSportFilter}
        selectedWaveFilter={selectedWaveFilter}
        changeIndividual={changeIndividual}
        selectedTab={selectedTab}
        selectedCategory={selectedCategory}
        segmentOrder={segmentOrder}
        setSelectedUser={setSelectedUser}
      />
    );
  };

  return (
    <div className='home'>
      {(showLoading || showIndividualFavoriteLoading || showSecondLoading) && (
        <Loading />
      )}
      {showFilterModal && (
        <FilterModal
          setShowLoading={setShowLoading}
          isIndividual
          closeModal={() => {
            setShowFilterModal(false);
          }}
          selectedCityFilter={selectedCityFilter}
          selectedSegmentFilter={selectedSegmentFilter}
          selectedGenderFilter={selectedGenderFilter}
          selectedGenderCategoryFilter={selectedGenderCategoryFilter}
          selectedAgeCategoryFilter={selectedAgeCategoryFilter}
          selectedSportFilter={selectedSportFilter}
          selectedWaveFilter={selectedWaveFilter}
          setSelectedCityFilter={setSelectedCityFilter}
          setSelectedSegmentFilter={setSelectedSegmentFilter}
          setSelectedGenderFilter={setSelectedGenderFilter}
          setSelectedWaveFilter={setSelectedWaveFilter}
          setSelectedGenderCategoryFilter={setSelectedGenderCategoryFilter}
          setSelectedSportFilter={setSelectedSportFilter}
          setChangeIndividual={setChangeIndividual}
          selectedCategory={selectedCategory}
        />
      )}
      {showUserInfoModal && selectedUser && (
        <UserInfoModal
          closeModal={() => {
            setShowUserInfoModal(false);
            setSelectedUser(null);
          }}
          selectedUser={selectedUser}
        />
      )}
      <Header
        resetFilters={resetFilters}
        showReset={
          !!selectedCityFilter ||
          !!selectedGenderFilter ||
          !!selectedSegmentFilter ||
          !!selectedGenderCategoryFilter ||
          !!selectedWaveFilter ||
          !!selectedSportFilter
        }
      />
      <div className='banner hide-mobile'>
        <img src={banner} alt='adds' />
      </div>
      <div className='banner hide-desktop'>
        <img src={bannerMobile} alt='adds' />
      </div>
      <div className='fixed-header'>
        <Nav
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          openFilter={() => {
            setShowFilterModal(true);
          }}
        />
        <Tabs tabs={TABS} tab={3} onChange={onTabChange} />
        <TableRankingTitle
          segmentOrder={segmentOrder}
          setSegmentOrder={setSegmentOrder}
        />
      </div>
      {renderTable()}
    </div>
  );
};

export default Home;
