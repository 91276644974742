import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import { Row, Col } from 'react-flexbox-grid';

import filter from '../../assets/images/filtros.svg';
import './nav.scss';

const AMATEUR = 'amateur';
const PROFESSIONAL = 'professional';

const categoryArray = [
  { value: AMATEUR, name: 'LOS MORTALES' },
  { value: PROFESSIONAL, name: 'NARIÑO CHALLENGE' },
  { value: '', name: 'TODOS' },
];

const Nav = ({ selectedCategory, setSelectedCategory, openFilter }) => {
  return (
    <nav className='nav'>
      <Col xs={12}>
        <Row className='nav-row'>
          <Col xs md={6} className='hide-mobile'>
            <Row className='segment-select-row'>
              <Col xs className='select-auto'>
                <FormControl className='segment-select-container'>
                  <Select
                    autoWidth
                    className='segment-select'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={selectedCategory}
                    displayEmpty
                    onChange={(event) => {
                      setSelectedCategory(event.target.value);
                    }}
                  >
                    {categoryArray.map((categoryItem) => {
                      return (
                        <MenuItem
                          key={`index${categoryItem.value}`}
                          value={categoryItem.value}
                        >
                          {categoryItem.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            </Row>
          </Col>
          {openFilter && (
            <Col md={6} className='hide-mobile'>
              <div className='filter-container'>
                <img
                  onClick={() => {
                    openFilter();
                  }}
                  src={filter}
                  alt='filter'
                  className='nav_filter'
                />
              </div>
            </Col>
          )}
          <Col xs={12} className='hide-desktop select-mobile-container'>
            <FormControl className='segment-select-container'>
              <Select
                className='segment-select'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedCategory}
                displayEmpty
                onChange={(event) => {
                  setSelectedCategory(event.target.value);
                }}
              >
                {categoryArray.map((categoryItem) => {
                  return (
                    <MenuItem
                      key={`index${categoryItem.value}`}
                      value={categoryItem.value}
                    >
                      {categoryItem.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {openFilter && (
              <div className='filter-container'>
                <img
                  onClick={() => {
                    openFilter();
                  }}
                  src={filter}
                  alt='filter'
                  className='nav_filter'
                />
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </nav>
  );
};

Nav.propTypes = {
  openFilter: PropTypes.func.isRequired,
};

export default Nav;
